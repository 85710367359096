import type { NextRouter } from 'next/router';
import qs from 'qs';

import { isMobileWeb } from '@zep/utils';
const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL as string;
const ZEP_FRONTEND = 'https://localhost:3000';
const isLocal = process.env.NEXT_PUBLIC_STAGE === 'LOC';
const ADMIN_URL = process.env.NEXT_PUBLIC_ADMIN_URL;

// 개발 용이성을 위해 분기처리하였음
export const baseUrl = isLocal ? ZEP_FRONTEND : NEXT_PUBLIC_BASE_URL;
export const PagePath = {
  Root: baseUrl,
  AppUpdateGuide: `${baseUrl}/home/update`,
  Terms: `${baseUrl}/accounts/terms`,
  Privacy: `${baseUrl}/accounts/privacy`,
  ZEMCharge: `${baseUrl}/accounts/charge`,
  DonationHistory: `${baseUrl}/manage/donations`,
  Edit: `${NEXT_PUBLIC_BASE_URL}/edit`,
  Signin: `${baseUrl}/accounts/signin`,
  AssetStore: `${baseUrl}/store`,
  Admin: `${ADMIN_URL}`,
  Wallet: `${baseUrl}/web3/zetawallet`,
  CheckoutPlan: `${baseUrl}/checkout`,
  404: `${baseUrl}/404`,
} as const;

/**
 * Notice
 * !! 호출이 까다롭거나 자주 이동 사용 되는 페이지만 함수로 만들것
 * !! 나머지는 상수로 만들어서 사용하세요
 * */

export const redirectHome = () => {
  redirect(PagePath.Root);
};

export const redirectSigninPage = (redirectURL = window.location.href) => {
  redirect(`${PagePath.Signin}?redirectURL=${encodeURI(redirectURL)}`);
};

export const openAssetStorePage = () => {
  window.open(PagePath.AssetStore, '_blank');
};

export const redirect404Page = () => {
  redirect(`${PagePath['404']}`);
};

export const goEditPage = ({
  spaceHashId,
  mapHashId,
}: {
  spaceHashId: string;
  mapHashId: string;
}) => {
  window.location.href = `${PagePath.Edit}/${spaceHashId}/${mapHashId}`;
};

export const openZEMChargingPage = () => {
  window.open(PagePath.ZEMCharge, '_blank');
  return;
};

export const openPlanUpgradePage = ({
  planType = 'pro',
  maxCcu,
  spaceHashId,
  subscriptionPeriod = 365,
}: {
  planType?: string;
  maxCcu?: number;
  spaceHashId: string;
  subscriptionPeriod?: number;
}) => {
  const queryString = qs.stringify({
    plan: planType.toLowerCase(),
    maxCcu,
    spaceHashId,
    subscriptionPeriod,
  });

  window.open(
    `${PagePath.CheckoutPlan}?${queryString}`,
    isMobileWeb() ? '_self' : '_blank',
  );

  return;
};

const isSameNextApp = (href: string) => {
  const next = (window as NextInWindow).next;
  if (typeof next === 'undefined') return false;

  const currentAppBasePath = next.router.basePath;

  const { pathname } = new URL(href);
  return pathname.startsWith(currentAppBasePath);
};

const redirect = (href: string) => {
  if (isSameNextApp(href)) {
    return (window as NextInWindow).next?.router.replace(href);
  } else {
    return (window.location.href = href);
  }
};

interface NextInWindow {
  next?: {
    router: NextRouter;
  };
}
