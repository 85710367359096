import React from 'react';
import { Menu } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import cns from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { QUERY_KEY_ME } from '@zep/apis';
import { useAuth } from '@zep/auth';
import { openZEMChargingPage } from '@zep/common/routes';
import { ZemColorIcon } from '@zep/icons';
import { Button } from '@zep/ui';

import { LoginUserMenuList } from '../../../consts';

import S from './Header.module.scss';

/**
 * @returns 사용자 메뉴 컴포넌트
 */
const UserMenu = () => {
  const { user, logout } = useAuth();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { t } = useTranslation(['layout'], { useSuspense: true });
  const { locale } = router;

  const handleLogout = () => {
    router.push('/home/landing').then(logout);
  };

  const toggleUserMenu = (isOpen: boolean) => {
    if (isOpen) {
      queryClient.invalidateQueries(QUERY_KEY_ME);
    }
  };

  return (
    <>
      <Menu as="div" className={cns(S.login_user_menu, S.header_menu)}>
        {({ open, close: closeUserMenu }) => (
          <>
            <Menu.Button id="memberMenu" onClick={() => toggleUserMenu(!open)}>
              <span>{user?.name}</span>
              <Image
                width={16}
                height={16}
                src={`/images/light/common/caret-${open ? 'up' : 'down'}.svg`}
                alt="dropdown caret"
              />
            </Menu.Button>
            <Menu.Items
              as="div"
              className={cns(S.dropdown_menu_end, S.menu_items)}>
              <div className={S.menu_top}>
                <span>{user?.snsId}</span>
                <div className={S.zem_menu}>
                  <span className={S.current_zem}>
                    <ZemColorIcon width={20} height={20} />
                    <span>{user?.totalCashMoney.toLocaleString()} ZEM</span>
                  </span>
                  <Button
                    as={'a'}
                    variant="secondary"
                    size="xs"
                    target="_blank"
                    className={S.charge_zem_link}
                    onClick={() => {
                      openZEMChargingPage();
                      closeUserMenu();
                    }}>
                    {t('layout:header_zem_charge')}
                  </Button>
                </div>
              </div>

              <span className={S.contour_line} />

              <div className={S.menu_middle}>
                {LoginUserMenuList.filter(
                  users => !users.imageResource && !users.hidden,
                ).map(userMenus => (
                  <Menu.Item as="span" key={userMenus.path}>
                    {({ active }) => (
                      <Link
                        href={
                          ((userMenus.useBaseURL &&
                            process.env.NEXT_PUBLIC_BASE_URL) ||
                            '') +
                          (locale === 'ko'
                            ? userMenus.path
                            : userMenus.pathEn ?? userMenus.path)
                        }
                        className={cns({ [S.menu_hover]: active })}
                        onClick={closeUserMenu}
                        prefetch={false}>
                        {userMenus.imageResource && (
                          <Image
                            width={20}
                            height={20}
                            src={`/images/${userMenus.imageResource}.svg`}
                            alt="menu icon"
                          />
                        )}
                        {t(userMenus.name)}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>

              <span className={S.contour_line} />

              <div className={S.menu_middle}>
                {LoginUserMenuList.filter(
                  users => users.imageResource && !users.hidden,
                ).map(userMenus => (
                  <Menu.Item as="span" key={userMenus.path}>
                    {({ active }) => (
                      <Link
                        href={
                          ((userMenus.useBaseURL &&
                            process.env.NEXT_PUBLIC_BASE_URL) ||
                            '') +
                          (locale === 'ko'
                            ? userMenus.path
                            : userMenus.pathEn ?? userMenus.path)
                        }
                        className={cns({ [S.menu_hover]: active })}
                        onClick={closeUserMenu}
                        prefetch={false}>
                        {userMenus.imageResource && (
                          <Image
                            width={20}
                            height={20}
                            src={`/images/${userMenus.imageResource}.svg`}
                            alt="favorite"
                          />
                        )}
                        {t(userMenus.name)}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
              <span className={S.contour_line} />
              <div className={S.menu_middle}>
                <Menu.Item as="span">
                  {({ active }) => (
                    <button
                      type="button"
                      className={cns(S.user_menu_button, {
                        [S.menu_hover]: active,
                      })}
                      onClick={handleLogout}>
                      <Image
                        width={20}
                        height={20}
                        src="/images/light/layout/logout-black.svg"
                        alt="logout"
                      />
                      {t('layout:header_layout_signout')}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </>
        )}
      </Menu>
    </>
  );
};

export default UserMenu;
