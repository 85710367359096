import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import {
  CheckSmallCircleFillIcon,
  ExclamationmarkSmallCircleIcon,
} from '@zep/icons';
import { useViewportDevice } from '@zep/react';

import S from './Toast.module.scss';

export const Toast = () => {
  const [headerMarginRight, setHeaderMarginRight] = useState('100px');
  const { isMobile } = useViewportDevice();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const header = document.querySelector('#header_with_margin')!;
      const headerStyle = header && window.getComputedStyle(header, null);

      headerStyle &&
        setHeaderMarginRight(headerStyle.getPropertyValue('margin-right'));
    });

    resizeObserver.observe(document.body);
    return () => resizeObserver.disconnect();
  }, []);
  return (
    <Toaster
      position={isMobile ? 'bottom-center' : 'bottom-right'}
      containerClassName={S.toast_container}
      toastOptions={{
        className: S.toast,
        style: { marginRight: (!isMobile && headerMarginRight) || 'unset' },
        success: { icon: <CheckSmallCircleFillIcon color="#15E4BF" /> },
        error: { icon: <ExclamationmarkSmallCircleIcon color="#FF5353" /> },
      }}
    />
  );
};
