import { ReactNode } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import S from './Loading.module.scss';

export const Loading = NiceModal.create((props: { text?: string }) => {
  const { text } = props;
  return (
    <div className={S.background}>
      <div className={S.loading_content}>
        <div className={S.loading_wrapper}>
          <svg className={S.svg}>
            <circle className={S.circle} cx="50%" cy="50%" r="25"></circle>
          </svg>
          {text && <label className={S.text}>{text ?? 'LOADING'}</label>}
        </div>
      </div>
    </div>
  );
});

export const LoadingSpinner = ({
  text,
  description,
}: {
  text: string | ReactNode;
  description?: string | ReactNode;
}) => {
  return (
    <div className={S.background}>
      <div className={S.loading_content}>
        <div className={S.loading_wrapper}>
          <svg className={S.svg}>
            <circle className={S.circle} cx="50%" cy="50%" r="25"></circle>
          </svg>
          <div className={S.info}>
            {text && <label className={S.text}>{text ?? 'LOADING'}</label>}
            {description && <label className={S.desc}>{description}</label>}
          </div>
        </div>
      </div>
    </div>
  );
};
