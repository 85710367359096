import { PropsWithChildren, SVGProps } from 'react';
import { Disclosure } from '@headlessui/react';
import cns from 'classnames';

import S from './Accordion.module.scss';

const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.293 7.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414L12 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6Z"
      fill="currentColor"
    />
  </svg>
);

export const AccordionText = ({
  type = 'info',
  children,
}: PropsWithChildren<{ type?: 'info' | 'danger' | 'success' }>) => {
  return (
    <span className={cns(S.text, { [S[`${type}`]]: type })}>{children}</span>
  );
};

export const AccordionButton = ({
  children,
  onClick,
}: AccordionButtonProps) => {
  return (
    <Disclosure.Button className={S.button}>
      <div className={S.button_field} onClick={onClick}>
        {children}
        <ChevronIcon className={S.icon} aria-hidden="true" />
      </div>
    </Disclosure.Button>
  );
};

export const AccordionPanel = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <Disclosure.Panel className={cns(S.panel, className)}>
      {children}
    </Disclosure.Panel>
  );
};

export const Accordion = ({
  isOpen,
  className,
  children,
}: PropsWithChildren<{ isOpen?: boolean; className?: string }>) => {
  return (
    <Disclosure defaultOpen={isOpen}>
      {({ open }) => (
        <div className={cns(S.accordion, { [S.open]: open }, className)}>
          {children}
        </div>
      )}
    </Disclosure>
  );
};

type AccordionButtonProps = PropsWithChildren & {
  onClick?: () => void;
};
